import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout'
import TextBanner from '../components/text-banner/text-banner';
import ItemEditorial from "../components/6item-editorial/6item-editorial";
class Insights extends React.Component {
    render() {
        const allContentfulBlogPost = get(this, 'props.data.allContentfulBlogPost')
        const insightsHeader = get(this, 'props.data.insightsHeader')

        return (
            <Layout location={this.props.location}>
                <Seo title="Insights" />
                    <div className="container-fluid container-fluid--page">
                        <TextBanner textOne={insightsHeader.textOne} textTwo={insightsHeader.textTwo} />
                        <ItemEditorial posts={allContentfulBlogPost.nodes} />
                    </div>
            </Layout>
        )
    }
}

export default Insights;

export const query = graphql`
query Insights {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        nodes {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            heroImage {
                url
            }
            description {
                raw
            }
        }
    }
    insightsHeader: contentfulTextBanner(textBannerName: {eq: "insightsHeader"}) {
        textBannerName
        textOne
        textTwo
    }
}
`

